<template>
  <span :class="[classes, 'ui']" />
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return [
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value)
      },
    },
  },
  computed: {
    classes() {
      return {
        inline: this.inline,
        loader: this.loading,
        active: this.loading,
        [this.size]: true,
      }
    },
  },
}
</script>
