<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    one: {
      type: Boolean,
      default: false,
    },
    two: {
      type: Boolean,
      default: false,
    },
    three: {
      type: Boolean,
      default: false,
    },
    four: {
      type: Boolean,
      default: false,
    },
    five: {
      type: Boolean,
      default: false,
    },
    six: {
      type: Boolean,
      default: false,
    },
    seven: {
      type: Boolean,
      default: false,
    },
    eight: {
      type: Boolean,
      default: false,
    },
    nine: {
      type: Boolean,
      default: false,
    },
    ten: {
      type: Boolean,
      default: false,
    },
    eleven: {
      type: Boolean,
      default: false,
    },
    twelve: {
      type: Boolean,
      default: false,
    },
    thirteen: {
      type: Boolean,
      default: false,
    },
    fourteen: {
      type: Boolean,
      default: false,
    },
    fifteen: {
      type: Boolean,
      default: false,
    },
    sixteen: {
      type: Boolean,
      default: false,
    },
    middleAligned: {
      type: Boolean,
      default: false,
    },
    rightAligned: {
      type: Boolean,
      default: false,
    },
    leftAligned: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        // Fomantic actually checks for 'one wide,' 'two wide' etc as a *substring*
        // of the class attribute in their CSS. B/c of this we have to act as if there
        // are class names with spaces in the middle, i.e. class='column wide one' would
        // not behave as expected.
        column: true,
        'one wide': this.one,
        'two wide': this.two,
        'three wide': this.three,
        'four wide': this.four,
        'five wide': this.five,
        'six wide': this.six,
        'seven wide': this.seven,
        'eight wide': this.eight,
        'nine wide': this.nine,
        'ten wide': this.ten,
        'eleven wide': this.eleven,
        'twelve wide': this.twelve,
        'thirteen wide': this.thirteen,
        'fourteen wide': this.fourteen,
        'fifteen wide': this.fifteen,
        'sixteen wide': this.sixteen,
        'middle aligned': this.middleAligned,
        'right aligned': this.rightAligned,
        'left aligned': this.leftAligned,
        'center aligned': this.centered,
      }
    },
  },
}
</script>
