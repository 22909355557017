<template>
  <header :class="classes">
    <slot />
  </header>
</template>

<script>
export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        header: true,
        centered: this.centered,
      }
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 1em;
}

.centered {
  text-align: center;
}
</style>
