<template>
  <div>
    <div class="background-circle left-circle" />
    <div class="background-circle right-circle" />
    <a href="https://www.teamgather.co">
      <g-image :src="gatherLogo" size="small" class="logo" alt="Gather logo" />
    </a>
    <page-header centered>
      <h1>Gather in under 2 minutes</h1>
    </page-header>
    <loading v-show="loading" :loading="loading" />
    <container v-show="!loading">
      <grid>
        <grid-column sixteen centered>
          <iframe
            ref="video"
            width="800"
            height="450"
            src="https://www.youtube.com/embed/2YlMe_s-FNw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </grid-column>
        <grid-column sixteen centered>
          <router-link
            :to="{ name: 'moments' }"
            tag="button"
            class="ui primary button large"
          >
            💫 Create Your First Workflow
          </router-link>
        </grid-column>
      </grid>
    </container>
  </div>
</template>

<script>
import gImage from '@/components/v2/image.vue'
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'

import gatherLogo from '@/assets/img/gather-logo-blue.png'

export default {
  components: {
    gImage,
    pageHeader,
    grid,
    gridColumn,
    loading,
    container,
  },
  data() {
    return {
      gatherLogo,
      loading: true,
    }
  },
  mounted() {
    this.$emit('changeNav', { type: 'noNavBar' })
    this.$refs.video.addEventListener('load', () => {
      this.loading = false
    })
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.logo {
  display: inline !important;
  max-width: 10em;
  margin: 1em 0 0 1em;
}

.background-circle {
  position: fixed;
  z-index: -1;
  width: 80vh;
  height: 80vh;
  border-radius: 100%;
  opacity: 0.5;
  transition: width 1s, height 1s;
}

.left-circle {
  top: -250px;
  right: auto;
  bottom: auto;
  left: -400px;
  background-color: @light-green;
}

.right-circle {
  top: auto;
  right: -400px;
  bottom: -200px;
  left: auto;
  background-color: @light-blue;
}

@media screen and (max-width: 1000px) {
  .background-circle {
    width: 70vh;
    height: 70vh;
  }
}

@media screen and (max-width: 768px) {
  .background-circle {
    width: 0;
    height: 0;
    transition: width 2s, height 2s;
  }
}
</style>
