<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    stackable: {
      type: Boolean,
      default: false,
    },
    relaxed: {
      type: Boolean,
      default: false,
    },
    celled: {
      type: Boolean,
      default: false,
    },
    container: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    doubling: {
      type: Boolean,
      default: false,
    },
    equalWidth: {
      type: Boolean,
      default: false,
    },
    one: {
      type: Boolean,
      default: false,
    },
    two: {
      type: Boolean,
      default: false,
    },
    three: {
      type: Boolean,
      default: false,
    },
    four: {
      type: Boolean,
      default: false,
    },
    five: {
      type: Boolean,
      default: false,
    },
    six: {
      type: Boolean,
      default: false,
    },
    seven: {
      type: Boolean,
      default: false,
    },
    eight: {
      type: Boolean,
      default: false,
    },
    nine: {
      type: Boolean,
      default: false,
    },
    ten: {
      type: Boolean,
      default: false,
    },
    eleven: {
      type: Boolean,
      default: false,
    },
    twelve: {
      type: Boolean,
      default: false,
    },
    thirteen: {
      type: Boolean,
      default: false,
    },
    fourteen: {
      type: Boolean,
      default: false,
    },
    fifteen: {
      type: Boolean,
      default: false,
    },
    sixteen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        grid: true,
        stackable: this.stackable,
        relaxed: this.relaxed,
        celled: this.celled,
        container: this.container,
        centered: this.centered,
        doubling: this.doubling,
        'equal width': this.equalWidth,
        'one column': this.one,
        'two column': this.two,
        'three column': this.three,
        'four column': this.four,
        'five column': this.five,
        'six column': this.six,
        'seven column': this.seven,
        'eight column': this.eight,
        'nine column': this.nine,
        'ten column': this.ten,
        'eleven column': this.eleven,
        'twelve column': this.twelve,
        'thirteen column': this.thirteen,
        'fourteen column': this.fourteen,
        'fifteen column': this.fifteen,
        'sixteen column': this.sixteen,
      }
    },
  },
}
</script>
